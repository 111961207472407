import React from 'react'
import 'bootstrap/dist/css/bootstrap.min.css'
import { Container } from 'react-bootstrap'
import Banner from '../components/Banner'
import About from '../components/About'
import Footer from '../components/Footer'
import Projects from '../components/Projects'

export default function Home() {
  return (
    <Container>
      <Banner />
      <Projects />
      <About />
      <Footer />
    </Container>
  )
}
