import React from 'react'
import 'bootstrap/dist/css/bootstrap.min.css'
import Fade from 'react-reveal/Fade'
import './Banner.css'

const Banner = () => {
  return (
    <div id="banner" className="jumbotron">
      <Fade left duration={1000} delay={500} distance="30px">
        <h1 className="banner-name">
          {'Hi, my name is '}
          <label className="banner-my-name">Sarah</label>
        </h1>
        <h1 className="banner-title">
          {'I like to code '}
          <span role="img" aria-label="happy-face-emoji">
            &#128513;
          </span>
        </h1>
      </Fade>
      <Fade left duration={1200} delay={1000} distance="30px">
        <div id="table-of-contents">
          <a className="contact-link" href="https://github.com/parks96uw">
            {'github'}
          </a>
          {' · '}
          <a
            className="contact-link"
            href="https://www.linkedin.com/in/sarahpark96"
          >
            {'linkedin'}
          </a>
          {' · '}
          <a className="contact-link" href="mailto: sarah@codebysarah.dev">
            {'contact me'}
          </a>
        </div>
      </Fade>
    </div>
  )
}

export default Banner
