import React from 'react'
import 'bootstrap/dist/css/bootstrap.min.css'
import Fade from 'react-reveal/Fade'
import { ListGroup } from 'react-bootstrap'
import './About.css'
import data from './data/data.json'

const About = () => {
  const aboutData = data[1]

  const renderList = data => {
    return data?.about?.map(({ id, note }) => {
      return <ListGroup.Item key={id}>{note}</ListGroup.Item>
    })
  }

  return (
    <div id="about">
      <h1 className="about-title">about me</h1>
      <ListGroup className="about-details">
        <Fade top duration={1000} delay={500} distance="30px">
          {renderList(aboutData)}
        </Fade>
      </ListGroup>
    </div>
  )
}

export default About
