import React from 'react'
import 'bootstrap/dist/css/bootstrap.min.css'
import { Card } from 'react-bootstrap'
import Fade from 'react-reveal'
import './Projects.css'
import data from './data/data.json'

const Projects = () => {
  const projectData = data[0]

  const renderProjects = data => {
    return data?.projects?.map(({ title, description, link }) => {
      return (
        <Card className="project-card" key={title}>
          <Card.Title className="card-title">{title}</Card.Title>
          <Card.Text className="card-text">{description}</Card.Text>
          <Card.Link className="card-link" href={link}>
            Try It Out
          </Card.Link>
        </Card>
      )
    })
  }

  return (
    <div id="projects">
      <h1 className="projects-title">side projects:</h1>
      <Fade down duration={1000} delay={500} distance="30px">
        {renderProjects(projectData)}
      </Fade>
    </div>
  )
}

export default Projects
