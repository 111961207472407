import React from 'react'
import 'bootstrap/dist/css/bootstrap.min.css'
import './Footer.css'

const Footer = () => {
  return (
    <footer className="footer">
      <div>
        <p className="footer-text">
          Built with
          <a className="rlinks" href="https://www.gatsbyjs.org/">
            Gatsby
          </a>
          and
          <a className="rlinks" href="https://reactjs.org/">
            React
          </a>
          &copy; {new Date().getFullYear().toString()}{' '}
        </p>
      </div>
    </footer>
  )
}

export default Footer
